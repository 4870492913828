/* TYPO */
@font-face {
    font-family: amoebia;
    src: url("/static/webfonts/amoebia/amoebia-drop-regular.otf") format("opentype");
}

.bg-gray-light {
    background-color: rgba(241, 241, 241, 0.35);;
}
.no-background-color {
    background-color: transparent !important;
}

h1 strong, h2 strong, h3 strong,
h4 strong, h5 strong, h6 strong {
    color: var(--color-red);
}
strong {
    font-weight: 900;
}
.small strong {
    font-weight: 500;
}


.fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
    min-height: 360px;
}

/* IMAGE ALIGNEMENT */
img.align-left,
img.align-right {
    margin-bottom: 10px;
    margin-top: 10px;
}
img.align-left {
    float: left;
    margin-right: 20px;
}
img.align-right {
    float: right;
    margin-left: 20px;
}


/* NAVIGATION */
.inner-nav>ul {
    border-bottom: 1px solid var(--color-dark-1);
}
.inner-nav>ul>li>a {
    color: var(--color-dark-1);
    opacity: 1;
    font-weight: bold;
}
.inner-nav>ul>li>a.selected,
.inner-nav>ul>li>a:hover {
    color: var(--color-red);
}
.inner-nav>ul>li>a.selected:after {
/*.inner-nav>ul>li>a:hover:after {*/
    background-color: var(--color-red);
    content: "";
    height: 1px;
    position: absolute;
    width: calc(100% + 10px);
    display: block;
    bottom: -1px;
    left: -5px;
    border-left: 2px solid #ffffff8c;
    border-right: 2px solid #ffffff8c;
}

.mobile-on .desktop-nav {
    display: none;
    left: -20px;
    padding: 0 15px;
    position: absolute;
    top: 100%;
    width: calc(100% + 40px);
    z-index: 100;
    height: 100vh;
    background: hsla(0, 0%, 7%, .995);
}
.mobile-on .desktop-nav ul li a{
    text-align: center;
}
.mobile-on .desktop-nav ul li a {
    padding: 20px 10px;
    font-size: 20px;
}

/* HOME */
.pageHome .logo {
    display: none;
}
.pageHome .inner-nav>ul {
    border-bottom: 0;
}
.pageHome .inner-nav>ul>li>a {
    color: var(--color-primary-light-1)
}
.mobile .pageHome .body-scrolled .inner-nav>ul>li>a {
    color: var(--color-primary-light-1)
}

.pageHome .mobile-nav-icon:after,
.pageHome .mobile-nav-icon:before,
.pageHome .mobile-nav-icon {
     background: var(--color-gray-light-1);
}
.pageHome .body-scrolled .mobile-nav-icon:after,
.pageHome .body-scrolled .mobile-nav-icon:before,
.pageHome .body-scrolled .mobile-nav-icon {
     background: var(--color-dark-1);
}
.pageHome .body-scrolled .mobile-nav.active .mobile-nav-icon {
    background: transparent;
}

.pageHome .body-scrolled .inner-nav>ul {
    border-bottom: 1px solid var(--color-dark-1);
}
.pageHome .body-scrolled .logo {
    display: block;
}
.pageHome .body-scrolled .inner-nav>ul>li>a {
    color: var(--color-dark-1);
}


/*.mobile-on .desktop-nav,*/
/*.mobile-on .desktop-nav ul {*/
/*    background-color: #FFFFFF;*/
/*}*/

.event__headerLinkAll {
    text-align: right;
}

/* CATALOGUE */
.work-intro {
    transform: none;
}
.work-item a:hover .work-intro {
    transform: none;
}

.work-descr {
    height: 160px;
}

.work-descr strong {
    font-weight: normal;
}
.work-categories {
    display: block;
    color: var(--color-gray-1);
    font-size: 15px;
    line-height: 1.4;
    text-transform: none;
    transform: translateZ(0);
    margin-bottom: 10px;
}

/* CATALOGUE DETAIL */
.work-navigation a,
.work-navigation div {
    cursor: default;
    display: block;
    float: left;
    overflow: hidden;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 33.33333%;
}
.work-navigation .deactivate {
    visibility: hidden;
}
.work-navigation.light-content span:hover {
    background-color: transparent;
}

/* BUTTONS */
.btn-mod.btn-border-w {
    color: var(--color-dark-1);
    border-color: var(--color-dark-1);
}
.btn-mod.btn-border:focus, .btn-mod.btn-border:hover,
.btn-mod.btn-border-w:focus, .btn-mod.btn-border-w:hover {
    color: var(--color-dark-4);
    border-color: var(--color-dark-4);
}


.btn-mod.btn-border-white {
    color: #FFFFFF;
    border-color: #FFFFFF;
}

/* ========================== Events ========================*/
.event__headerDescription {
    display: inline-block;
    padding: 0 10px;
}
.featuredEvent__section .event__headerDescription {
    padding: 0 5px;
}
.event__headerDescription p {
    display: inline-block;
    margin-bottom: 0;
}
/* ========================== Accordion ========================*/
.accordion {
    border-bottom: 1px solid var(--color-dark-1);
}

.accordion-item:first-of-type .accordion-button,
.accordion-button, .accordion-button:not(.collapsed) {
    position: relative;
    font-size: 20px;
    color: var(--color-dark-1);
    text-decoration: none;
    padding: 20px 5px 20px 0;
    border-top: 1px solid var(--color-dark-1);
    transition: var(--transition-default);
    font-family: var(--bs-body-font-family);

}

.accordion-button:after {
    color: var(--color-dark-1);
    content: "\e91f";
    display: block;
    font-family: icons !important;
    font-size: 22px;
    height: 1em;
    line-height: 1em;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: var(--transition-default);
    width: 1em;
}
.accordion-button:after,
.accordion-button:not(.collapsed):after {
    background-image: none;
}
.accordion-button:not(.collapsed):after {
    transform: rotate(-180deg);
}

.accordion-button.eventDate__year--passed,
.accordion>dt>a.eventDate__year--passed {
    color: #898989 !important;
}
.accordion-button:focus {
    border-color: var(--color-dark-1);
    box-shadow: none;
}
.accordion-item {
    border: none;
}
.accordion-item,
.accordion-button,
.accordion-button:not(.collapsed) {
    background-color: transparent;
}

.eventDate_border {
    border-bottom: 1px solid #707070;
}
.eventDate_border:last-child {
    border-bottom: 0;
    margin-bottom: 40px;
}

.event__passedDate .eventDate__table {
    border-top: 1px solid #707070;
    margin-top: -20px;
}
.event__passedDate,
.event__passedDate .eventDate__date,
.event__passedDate .eventDate__event,
.event__passedDate .eventDate__place,
.event__passedDate .eventDate__placeName,
.event__passedDate .eventDate__externalLink,
.event__passedDate .eventDate__internalLink
{
    color: #898989 !important;
}

.event__passedDateTitle {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
}


/* BLOG WIDGET */
.widget-posts__img {
    display: inline-block;
    float: left;
}
.widget:first-child {
    padding-top: 0;
    border-top: 0;
}
.widget-posts-descr {
    display: inline-block;
    width: 60%;
    margin-left: 1rem;
    margin-top: -4px;
}
.widget .widget__title {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: .6rem;
    font-size: 22px;
}

/* FILES (DOWNLOADS) */
.download__list {
    margin-bottom: 20px;
}
.download__list--pl0 {
    padding-left: 0;
}
.download__item {
    list-style: none;
    margin-bottom: 20px;
    display: inline-block;
    margin-right: 1rem;
}
.download__item--block {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.download__itemSize  {
    font-size: 11px;
    display: inline-block;
    font-weight: 400;
}

/* FOOTER */
.footer__leftCol {
    padding-left: 25px;
    border-left: 1px solid #0000004a;
    margin-left: 13px;
}
.footer__leftCol p:last-child {
    padding-bottom: 0;
}

/* COOKIES - TARTE AU CITRONS */
#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitronAlertBig #tarteaucitronPersonalize, #tarteaucitronAlertBig #tarteaucitronPersonalize2, .tarteaucitronCTAButton, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
    background: transparent !important;
    border: 1px solid #fff;
}

#tarteaucitronRoot button.tarteaucitronCTAButton, #tarteaucitronRoot button#tarteaucitronCloseAlert, #tarteaucitronRoot button#tarteaucitronPrivacyUrl {
    border: 1px solid #fff !important;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
    color: #FFFFFF !important;
}
#tarteaucitronRoot button#tarteaucitronCloseAlert {
    display: none;
}


@media only screen and (max-width: 600px) {
    .inner-nav ul li .mn-sub li a {
        font-size: 18px;
        padding: 20px 10px;
    }
}